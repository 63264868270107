import React from "react"
import { Row, Col } from "antd"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { FacebookProvider, Like, Share } from "react-facebook"

import { useContentfulGeneral } from "../../contenful/querys/General"
import { useContenfulSocialNetworks } from "../../contenful/querys/SocialNetwork"
import one from "../../assets/images/feeds/1.png"
import two from "../../assets/images/feeds/2.png"
import three from "../../assets/images/feeds/3.png"
import four from "../../assets/images/feeds/4.png"
import five from "../../assets/images/feeds/5.png"
import six from "../../assets/images/feeds/6.png"
import like from "../../assets/images/like.png"

const FollowUs = () => {
  const { t } = useTranslation()

  const { languageISO639, countrieCurrent } = useSelector(state => state.language)
  const contenfulGeneral = useContentfulGeneral(languageISO639)
  const socialNetworks = useContenfulSocialNetworks(countrieCurrent || "CO")

  return (
    <div className="container">
      <div className="row-center">
        <div data-aos="fade-up" className="section-followus">
          <Row>
            <Col lg={12} xs={24} className="cont-footer">
              <h2 className="section-followus__title">
                {t("general.follous")}
              </h2>
              <div className="section-followus__descrip">
                {contenfulGeneral?.followus_description?.followus_description}
              </div>

              <FacebookProvider appId="198870331564777">
                <Like
                  href="https://www.facebook.com/liftit.co"
                  colorScheme="white"
                  showFaces
                  share
                />
              </FacebookProvider>

              <a target="_blank" href={socialNetworks?.facebook_url} className="section-followus__btn">
                <img className="like" src={like} alt="like" />
                {t("button.ilike")}
              </a>
              {/* <FacebookProvider appId="198870331564777">
                <Share href="https://www.facebook.com/liftit.co">
                  {({ handleClick, loading }) => (
                    <button
                      type="button"
                      className="section-followus__btn"
                      disabled={loading}
                      onClick={handleClick}
                    >
                      {t("button.shared")}
                    </button>
                  )}
                </Share>
              </FacebookProvider> */}
              <p className="section-followus__descrip">
                {contenfulGeneral?.follous_note}
              </p>
            </Col>
            <Col className="section-followus__img" lg={12} xs={24}>
              <div className="section-followus__imgGrid-container">
                <div className="grid-item">
                  <img src={one} alt="one" className="grid-item__one"/>
                </div>
                <div className="grid-item">
                  <img src={two} alt="two" className="grid-item__two"/>
                </div>
                <div className="grid-item">
                  <img src={three} alt="three" className="grid-item__three"/>
                </div>
                <div className="grid-item">
                  <img src={four} alt="four" className="grid-item__four"/>
                </div>
                <div className="grid-item">
                  <img src={five} alt="five" className="grid-item__five"/>
                </div>
                <div className="grid-item">
                  <img src={six} alt="six" className="grid-item__six"/>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default FollowUs
